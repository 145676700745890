export const LINK_TYPES = {
  external: "external",
  internal: "internal",
  form: "form",
  media: "media",
  mailto: "mailto",
  modelpopup: "modelpopup"
};

export const CTA_BUTTON_TYPES = {
  buyNow: "buy_now",
  enquire: "enquire",
  disclosurePopup: "disclosure_pop_up",
};
