import { FormsHOC as NextGenFormsHoc } from "@fwd-dep/nextgen-ui-lib";
import axios from "axios";
import { getCallbackEnquiryFormProps } from "components/EnquiryForm/getCallbackEnquiryFormProps";
import { getCallbackGeneralEnquiryFormProps } from "components/EnquiryForm/getCallbackGeneralEnquiryFormProps";
import { getEnquiryFormProps } from "components/EnquiryForm/getEnquiryFormProps";
import { getEnquiryServices } from "components/EnquiryForm/getEnquiryServices";
import { getCallbackCalculatorForm } from "components/ProductPlanList/getCallbackCalculatorForm";
import { getCalculatorForm } from "components/ProductPlanList/getEnquiryFormProps";
import {
  handleEditCalculateForm,
  handleEnquiryForm,
} from "components/ProductPlanList/getNextGenProductPlanList";
import I18nContext from "i18n/context/LanguageContext";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import gtmUtils from "utils/gtm";
import { resolveAbsoluteUrlInParagraph } from "utils/route";
import { getCountryCode, getFormatDate } from "utils/string";
import { useRouter } from "next/router";
import { ContentType } from "types/Content.inteface";
import { LeadFormContextProvider } from "shared-components/LeadFormContextProvider";
import { useIsHydrating } from "hooks/components/useHydrating";

interface ApiStatus {
  loading: boolean;
  apiStatus: string | null;
  error: boolean | null;
  errorCode: number | null;
  failCounter: number;
  message: string;
}

export type UserData = {
  gender: string;
  genderCd: string;
  dob: string;
  dobString: string | null;
  sumAssured: string;
  textSumAssured: string;
  plans: Record<string, any>;
  paymentFrequency: string; // will be enum
  isRecommended: boolean;
  isPackage: boolean;
  calculateType: string; // will be enum
  resultedPlanList: any[];
  selectedPaymentMode: string;
  productName: string;
};

export type CalculateProductType = {
  name: string;
  productCode: string;
  form: any;
  plans: any;
  planCards: any;
  confirmationForm: any;
  riders: any;
  type: string;
  planRecommender: any;
  constraints: any;
};

export type EnquiryContextType = {
  updateStatus: (data: any) => void;
  calculateProduct: (data: CalculateProductType) => void;
  handleFormModalState: (state: boolean) => void;
  userData: UserData;
  enquiryFormProps: any;
  productName: string;
};

export const EnquiryContext = React.createContext({} as EnquiryContextType);

export function EnquiryProvider(props: any) {
  const i18nContext = useContext(I18nContext);
  const language = i18nContext.language;
  const router = useRouter();
  const isHydrating = useIsHydrating();

  const defaultAPIStatus: ApiStatus = {
    loading: false,
    apiStatus: null,
    error: null,
    errorCode: null,
    failCounter: 0,
    message: "",
  };

  const defaultUserData: UserData = {
    gender: "",
    genderCd: "",
    dob: "",
    dobString: null,
    sumAssured: "",
    textSumAssured: "",
    plans: {},
    calculateType: "",
    paymentFrequency: "",
    resultedPlanList: [],
    isPackage: false,
    isRecommended: true,
    selectedPaymentMode: "",
    productName: "",
  };

  const CSFooter = props?.isErrorPage
    ? props?.value
    : props?.value?.data?.layout?.find(({ nameComponent }: any) => {
        return nameComponent == "CSFooter";
      });

  const enquiryRefs = props?.isErrorPage
    ? CSFooter?.general_enquiry_reference
    : CSFooter?.dataComponent?.general_enquiry_reference || [];

  const generalEnquiryRef = enquiryRefs?.find(
    (ref: any) => ref.category === "general"
  );

  const recruitmentEnquiryRef = enquiryRefs?.find(
    (ref: any) => ref.category === "recruitment"
  );

  const contactEnquiryRef = enquiryRefs?.find((ref: any) => {
    return (
      ref.category === "recruitment" &&
      ref.dictionary.table &&
      ref.dictionary.table?.value?.[0]?.value === "contact"
    );
  });

  const productEnquiryRef = enquiryRefs?.find(
    (ref: any) => ref.category === "product-plan"
  );
  const defaultAdditionalInfos =
    props?.value?.data?.layout?.[0]?.dataComponent?.additionalInfos?.[0];

  let initSessionStorage = "";
  if (typeof window !== "undefined") {
    initSessionStorage = sessionStorage.getItem("calculatedData") || "{}";
  }

  useEffect(() => {
    setSessionData(initSessionStorage);
    if (JSON.parse(initSessionStorage)?.locale !== language.locale) {
      sessionStorage.removeItem("calculatedData");
    }
  }, [initSessionStorage, language.locale]);

  const [sessionData, setSessionData] = useState(initSessionStorage);
  const [formModalState, setFormModalState] = useState(false);
  const [apiStatus, setApiStatus] = useState(defaultAPIStatus);
  const [productName, setProductName] = useState("");
  const [productId, setProductId] = useState("");
  const [productPlanId, setProductPlanId] = useState("");
  const [additionalInfos, setAdditionalInfos] = useState({
    dTCCampaignId:
      defaultAdditionalInfos?.product?.dtc_campaign_id ||
      props?.value?.publicConfigurations?.dtcCampaignId,
    requireULLicense:
      defaultAdditionalInfos?.product?.required_ul_license || "",
    expectedInsuranceType:
      defaultAdditionalInfos?.product?.expected_insurance_type || "",
    expectedProductName: "",
  });
  const [calculateProductName, setCalculateProductName] = useState(
    sessionData ? JSON.parse(sessionData).calculateProductName : ""
  );
  const [dictionary, setDictionary] = useState(
    sessionData ? JSON.parse(sessionData).dictionary : {}
  );
  const [userData, setUserData] = useState<UserData>(
    sessionData ? JSON.parse(sessionData).userData : defaultUserData
  );
  const [plans, setPlans] = useState(
    sessionData ? JSON.parse(sessionData).plans : []
  );
  const [planCards, setPlanCards] = useState([]);
  const [enquiryFormProps, setEnquiryFormProps] = useState(
    sessionData ? JSON.parse(sessionData).calculateFormProps : {}
  );
  const [constraints, setContrainsts] = useState(null);
  const [mappingCalculate, setMappingCalculate] = useState({});
  const [productCode, setProductCode] = useState<string>("");
  const [isProductPlan, setIsProductPlan] = useState<boolean>(false);
  const [isMindStrengthEnquiry, setIsMindStrengthEnquiry] =
    useState<boolean>(false);

  const [enquiryData, setEnquiryData] = useState<any>({
    productFormEnquiry: {},
    recruitmentFormEnquiry: {},
    contactFormEnquiry: {},
    callbackGeneralEnquiryFormProps: {},
    callbackProductEnquiryFormProps: {},
    callbackRecruitmentEnquiryFormProps: {},
    callbackContactEnquiryFormProps: {},
  });

  const {
    productFormEnquiry,
    callbackGeneralEnquiryFormProps,
    callbackProductEnquiryFormProps,
    callbackRecruitmentEnquiryFormProps,
    callbackContactEnquiryFormProps,
  } = enquiryData;

  useEffect(() => {
    setTimeout(() => {
      const productFormEnquiry = getEnquiryFormProps(
        productEnquiryRef,
        props?.value?.publicConfigurations,
        i18nContext
      ) as any;

      const generalFormEnquiry = getEnquiryFormProps(
        generalEnquiryRef,
        props?.value?.publicConfigurations,
        i18nContext
      ) as any;

      const recruitmentFormEnquiry = getEnquiryFormProps(
        recruitmentEnquiryRef,
        props?.value?.publicConfigurations,
        i18nContext
      ) as any;

      const contactFormEnquiry = getEnquiryFormProps(
        contactEnquiryRef,
        props?.value?.publicConfigurations,
        i18nContext
      ) as any;

      const callbackGeneralEnquiryFormProps =
        getCallbackGeneralEnquiryFormProps(generalFormEnquiry, i18nContext);

      const callbackProductEnquiryFormProps = getCallbackEnquiryFormProps(
        productFormEnquiry,
        productName,
        i18nContext,
        false
      );

      const callbackRecruitmentEnquiryFormProps = getCallbackEnquiryFormProps(
        recruitmentFormEnquiry,
        "",
        i18nContext,
        false
      );

      const callbackContactEnquiryFormProps = getCallbackEnquiryFormProps(
        contactFormEnquiry,
        "",
        i18nContext,
        false
      );
      setEnquiryData({
        productFormEnquiry,
        generalFormEnquiry,
        recruitmentFormEnquiry,
        contactFormEnquiry,
        callbackGeneralEnquiryFormProps,
        callbackProductEnquiryFormProps,
        callbackRecruitmentEnquiryFormProps,
        callbackContactEnquiryFormProps,
      });
    }, 0);
  }, []);
  const onSubmit = async (payload: any) => {
    const queryReason: string =
      payload?.data?.user?.profile?.contact?.email?.parameters?.queryReason ||
      "";
    const services: any = enquiryFormProps.form?.sitecore?.context?.services;

    if (!payload) {
      payload = { data: {} };
    }
    payload = mapAdditionalInfos(payload);

    setApiStatus({
      loading: true,
      apiStatus: null,
      error: false,
      errorCode: null,
      failCounter: 0,
      message: "",
    });

    if (
      payload?.data?.type === "calculate" ||
      payload?.data?.type === "recommender_plan"
    ) {
      let age = 0;

      if (payload?.data?.dob) {
        age = moment(new Date()).year() - moment(payload?.data.dob).year();
      }

      if (language.languageCode === "th") {
        age = age + 543;
      }

      const limitAge = (constraints as any)?.limitAge;
      const limitAmount = (constraints as any)?.limitAmount;

      if (age !== null && age !== undefined && limitAge && limitAmount) {
        if (age < limitAge && payload?.data?.sumAssured < limitAmount) {
          setApiStatus({
            loading: false,
            apiStatus: "fail",
            error: true,
            errorCode: 404,
            failCounter: 0,
            message: "",
          });
          return;
        }
      }

      let isPackage = false;
      let code = "";

      Object.entries(planCards).forEach((plan: any) => {
        if (plan?.[1]?.fields?.isPackage) {
          isPackage = true;
        }
        if (plan?.[1]?.fields?.baseSumassured === payload?.data?.sumAssured) {
          code = plan?.[1]?.fields?.planCode?.value;
        }
      });
      payload.data = {
        ...payload.data,
        productCode,
        plans,
        isRecommended: true,
        isPackage,
        code,
      };
    }

    if (typeof window !== "undefined") {
      const host = window.location.host;
      if (host.indexOf("mindstrength") >= 0 || isMindStrengthEnquiry) {
        payload.data = {
          ...payload.data,
          isMindStrength: true,
        };
      }
    }
    mappingDropdownValues(payload);

    try {
      const response = await axios({
        method: "POST",
        url: payload?.apiUrl,
        data: {
          ...payload?.data,
          language: language.languageCode,
          locale: language.locale,
          country: getCountryCode(
            props?.value?.publicConfigurations?.publicCountryCode || ""
          ),
          emailTemplates:
            JSON.parse(sessionData)?.calculateFormProps?.emailTemplate &&
            productName.includes(JSON.parse(sessionData)?.calculateProductName)
              ? "CALCULATED"
              : enquiryFormProps.formLinks?.emailTemplates || [],
          services: JSON.parse(sessionData)?.calculateFormProps?.services
            ?.address_to?.length
            ? getEnquiryServices(
                JSON.parse(sessionData)?.calculateFormProps?.services,
                queryReason
              )
            : getEnquiryServices(services, queryReason),
          csBranch: props?.value?.publicConfigurations?.csBranchName || "main",
        },
        headers: {
          entity: payload?.entity,
          Authorization: payload?.authorizationKey,
          ...(payload?.factorType
            ? { "factor-type": payload?.factorType }
            : null),
        },
      });

      if ([200, 201].indexOf(response?.status) >= 0) {
        if (response?.data !== "undefined") {
          if (response?.data.success) {
            setApiStatus({
              loading: false,
              apiStatus: "success",
              error: false,
              errorCode: 200,
              failCounter: 0,
              message: "",
            });

            if (
              !!(
                payload?.data.type === "calculate" ||
                payload?.data.type === "recommender_plan"
              ) &&
              response?.data?.result
            ) {
              handleSetDataCalulationForm(payload, response?.data.result);
            } else if (payload?.gtm) {
              gtmUtils.pushEvent(payload?.gtm);
            }
          } else {
            setApiStatus({
              loading: false,
              apiStatus: "fail",
              error: true,
              errorCode: response?.status,
              failCounter: 0,
              message: "-Request returned 0",
            });
          }
        } else {
          setApiStatus({
            loading: false,
            apiStatus: "fail",
            error: true,
            errorCode: response?.status,
            failCounter: 0,
            message: "-Request failed",
          });
        }
      } else {
        setApiStatus({
          loading: false,
          apiStatus: "fail",
          error: true,
          errorCode: response?.status,
          failCounter: 0,
          message: response?.data,
        });
      }
    } catch (error: any) {
      setApiStatus({
        loading: false,
        apiStatus: "fail",
        error: true,
        errorCode: 400,
        failCounter: 0,
        message: error.message,
      });
    }
  };

  const buildDictionary = useCallback(
    (enquiryRef: any, productName = "") => {
      setDictionary({
        "global-error-validation-msg": enquiryRef?.global_error_validation_msg,
        enquiry: enquiryRef?.form_type,
        expectedProductName: enquiryRef?.expected_product_name || productName,
        constraintMessageForm: resolveAbsoluteUrlInParagraph(
          enquiryRef?.constraint_message_form,
          i18nContext
        ),
        apiErrorMessageForm: resolveAbsoluteUrlInParagraph(
          enquiryRef?.api_error_message_form,
          i18nContext
        ),
        sending: enquiryRef?.sending,
        enterKeyInfo: enquiryRef?.next_button_hint,
        submitKeyInfo: enquiryRef?.submit_button_hint,
        genericRequiredErrorMessage: enquiryRef?.generic_required_error_message,
        minLengthErrorMessage: enquiryRef?.min_length_error_message || "",
        maxLengthErrorMessage: enquiryRef?.max_length_error_message || "",
      });
    },
    [props, enquiryFormProps]
  );

  const updateStatus = (data: any) => {
    const {
      productName,
      isGeneralEnquiryForm,
      additionalInfos,
      isFromCalculation,
      productId,
      productPlanId,
      typeOfEnquiry,
    } = data;
    if (isGeneralEnquiryForm) {
      buildDictionary(generalEnquiryRef);
      if (typeOfEnquiry === ContentType.mindstrengthEnquiry) {
        setIsMindStrengthEnquiry(true);
      }
      if (generalEnquiryRef?.additional_infos?.length > 0) {
        setAdditionalInfos({
          dTCCampaignId:
            generalEnquiryRef?.additional_infos?.[0]?.product
              ?.dtc_campaign_id || "",
          requireULLicense:
            generalEnquiryRef?.additional_infos?.[0]?.product
              ?.required_ul_license || "",
          expectedInsuranceType:
            generalEnquiryRef?.additional_infos?.[0]?.product
              ?.expected_insurance_type || "",
          expectedProductName: "",
        });
      }
      setEnquiryFormProps(callbackGeneralEnquiryFormProps);
    } else if (typeOfEnquiry === ContentType.recruitmentEnquiry) {
      buildDictionary(recruitmentEnquiryRef);
      if (recruitmentEnquiryRef?.additional_infos?.length > 0) {
        setAdditionalInfos({
          dTCCampaignId:
            recruitmentEnquiryRef?.additional_infos?.[0]?.product
              ?.dtc_campaign_id || "",
          requireULLicense:
            recruitmentEnquiryRef?.additional_infos?.[0]?.product
              ?.required_ul_license || "",
          expectedInsuranceType:
            recruitmentEnquiryRef?.additional_infos?.[0]?.product
              ?.expected_insurance_type || "",
          expectedProductName: "",
        });
      }
      setEnquiryFormProps(callbackRecruitmentEnquiryFormProps);
    } else if (typeOfEnquiry === ContentType.contactEnquiry) {
      buildDictionary(contactEnquiryRef);
      if (contactEnquiryRef?.additional_infos?.length > 0) {
        setAdditionalInfos({
          dTCCampaignId:
            contactEnquiryRef?.additional_infos?.[0]?.product
              ?.dtc_campaign_id || "",
          requireULLicense:
            contactEnquiryRef?.additional_infos?.[0]?.product
              ?.required_ul_license || "",
          expectedInsuranceType:
            contactEnquiryRef?.additional_infos?.[0]?.product
              ?.expected_insurance_type || "",
          expectedProductName: "",
        });
      }
      setEnquiryFormProps(callbackContactEnquiryFormProps);
    } else {
      const nameOfProduct =
        productName || additionalInfos?.product?.expectedProductName || "";
      setProductName(nameOfProduct);
      setProductId(productId || "");
      setProductPlanId(productPlanId || "");
      if (
        additionalInfos?.product?.dTCCampaignId ||
        props?.value?.publicConfigurations?.dtcCampaignId
      ) {
        setAdditionalInfos({
          dTCCampaignId:
            additionalInfos?.product?.dTCCampaignId ||
            props?.value?.publicConfigurations?.dtcCampaignId ||
            "",
          requireULLicense: additionalInfos?.product?.requireULLicense || "",
          expectedInsuranceType:
            additionalInfos?.product?.expectedInsuranceType || "",
          expectedProductName:
            additionalInfos?.product?.expectedProductName || "",
        });
      }
      buildDictionary(productEnquiryRef, nameOfProduct);

      let productEnquiryForm: any = callbackProductEnquiryFormProps;

      let sessionDataStored: string;

      if (sessionData === "{}") {
        sessionDataStored = sessionStorage.getItem("calculatedData") || "{}";
      } else {
        sessionDataStored = sessionData;
      }

      if (sessionDataStored !== "{}") {
        const userData = JSON.parse(sessionDataStored)?.userData;

        if (userData) {
          let confirmedProductEnquiryForm: any = getCallbackEnquiryFormProps(
            productFormEnquiry,
            nameOfProduct,
            i18nContext,
            true
          );

          if (data.riderList?.length) {
            setDictionary({
              ...dictionary,
              expectedProductName: `${
                dictionary.expectedProductName
              } - ${data.riderList?.map((rider: any) => {
                return rider?.title + " ";
              })}`,
            });
          }

          productEnquiryForm = {
            ...confirmedProductEnquiryForm,
            userData: {
              ...userData,
              productName: isFromCalculation
                ? calculateProductName
                : nameOfProduct,
              ...(isFromCalculation &&
                nameOfProduct && {
                  planName: nameOfProduct,
                }),
              riders: data.riderList?.map((d: any) => d.title),
              mappingCalculate: {
                ...mappingCalculate,
                riders: data.riderList?.map((rider: any) => rider?.mapping),
              },
            },
            calculateFormValues:
              JSON.parse(sessionDataStored)?.calculateFormProps
                ?.calculateFormValues,
            confirmationForm: {
              ...confirmedProductEnquiryForm?.confirmationForm,
              calculateFormValues:
                JSON.parse(sessionDataStored)?.calculateFormProps
                  ?.calculateFormValues,
            },
          };
        }
      }

      setEnquiryFormProps(productEnquiryForm);
    }
    setIsProductPlan(!!isFromCalculation);

    if (data.type === "recommender_plan") {
      setFormModalState(true);
    } else {
      setFormModalState(!formModalState);
    }
  };

  const calculateProduct = (data: CalculateProductType) => {
    const calculationForm = getCalculatorForm(
      data.form,
      props?.value?.publicConfigurations,
      i18nContext
    );

    setProductName(data.name);
    setCalculateProductName(data.name);
    setPlans(data.plans);
    setPlanCards(data.planCards);
    buildDictionary(data.form, data.name);
    setContrainsts(data.constraints);
    setProductCode(data.productCode);

    if (calculationForm?.mapping) {
      setMappingCalculate(calculationForm.mapping);
    }

    const planDictionary = (
      data.planRecommender?.dictionary?.value || []
    ).reduce(
      (obj: any, item: any) => Object.assign(obj, { [item.key]: item.value }),
      {}
    );

    if (sessionData && JSON.parse(sessionData)?.calculateFormProps) {
      setTimeout(() => {
        const stored = JSON.parse(sessionData).calculateFormProps;

        const newConformationForm = { ...data.confirmationForm, ...stored };

        setEnquiryFormProps({
          ...stored,
          productDetails: { ...stored.productDetails, type: data.type },
          userData,
          confirmationForm: newConformationForm,
          riders: data.riders,
          planRecommender: {
            ...(data.planRecommender || {}),
            dictionary: planDictionary,
          },
        });
      }, 0);
    } else {
      setEnquiryFormProps({
        ...getCallbackCalculatorForm(calculationForm, language, data.type),
        ...data.confirmationForm,
        services: calculationForm?.services,
        planRecommender: {
          ...(data.planRecommender || {}),
          dictionary: planDictionary,
        },
        userData,
        confirmationForm: { ...data.confirmationForm },
        riders: data.riders,
        emailTemplate: calculationForm?.emailTemplate,
        dotNumber: calculationForm?.sumAssured.dotNumber || false,
        dotNumberInConfirmPage:
          calculationForm?.sumAssured.dotNumberInConfirmPage || false,
        longDateFormat: calculationForm?.dobBlock?.longDateFormat || false,
      });
    }

    setFormModalState(!formModalState);
  };

  const handleSetDataCalulationForm = (payload: any, res: any) => {
    if (payload?.data && res) {
      const updateUserData = { ...userData };

      updateUserData.gender = payload.data.gender?.toUpperCase();
      updateUserData.genderCd = payload.data.genderCd;
      updateUserData.dob = payload.data.dob;
      updateUserData.dobString = enquiryFormProps.longDateFormat
        ? getFormatDate(
            payload.data.dob,
            i18nContext.language.languageCode,
            i18nContext.countryCode
          )
        : null;
      updateUserData.sumAssured = payload.data.sumAssured;
      updateUserData.textSumAssured = payload.data.textSumAssured;
      updateUserData.plans = plans;
      updateUserData.calculateType = "premium";
      updateUserData.resultedPlanList = res.plans;
      updateUserData.paymentFrequency = payload.data.paymentFrequencyCd;
      updateUserData.selectedPaymentMode = payload.data.paymentFrequencyCd;
      updateUserData.productName = payload.data.productName;

      setUserData(updateUserData);

      const recommendedPlan = res?.plans.find(
        (plan: any) => !!plan.isRecommended
      );

      const recommendedPlanCard = planCards.find(
        (card: any) =>
          card?.fields?.planCode?.value === recommendedPlan?.product?.plan?.code
      );

      const { sumAssured, productName } = payload.data;
      const { product } = res?.plans?.[0] || null;
      const coverage = get(recommendedPlanCard, "fields.gtmCoverage", []);
      const currency = get(recommendedPlanCard, "dictionary.currency", "");

      if (payload && !isEmpty(payload?.gtm)) {
        gtmUtils.pushEvent({
          ...payload?.gtm,
          user_preferences: {
            ...payload?.gtm.user_preferences,
            assurance_sum: sumAssured,
            based_on: "sum_assurance",
            contact_form: "meet_agent",
            contact_via: "phone",
            contact_topic: productName,
            premium_budget: sumAssured,
            currency,
          },
          ...(product && {
            plan: {
              title: product?.id || "",
              product: productName,
              premium: product?.amount?.baseAnnualPremium,
              sum_assured: product?.amount?.baseSumAssured,
              currency,
              coverage,
            },
          }),
        });
      }

      let recommendedPlanData = {};

      if (recommendedPlan && planCards.length) {
        recommendedPlanData =
          planCards.find((plan: any) => {
            let planCardsCode = "";
            if (plan?.fields?.planCode?.value) {
              planCardsCode = plan?.fields?.planCode?.value;
              if (plan?.fields?.productPlanOptionCd?.value)
                planCardsCode = `${planCardsCode}_${plan?.fields?.productPlanOptionCd?.value}`;
            }

            let recommendedPlanCode = "";
            if (recommendedPlan.product?.plan?.code) {
              recommendedPlanCode = recommendedPlan.product?.plan?.code;
              if (recommendedPlan.product?.plan?.productOption) {
                recommendedPlanCode = `${recommendedPlanCode}_${recommendedPlan.product?.plan?.productOption}`;
              }
            }

            return planCardsCode === recommendedPlanCode;
          }) ?? {};
      }

      const storeData = {
        userData: updateUserData,
        calculateFormProps: {
          ...enquiryFormProps,
          productDetails: {
            initializer: "bookAnAgent",
            type:
              payload?.data.type === "recommender_plan"
                ? "recommender"
                : "calculate",
          },
          calculateFormValues: payload?.data?.formValues,
          recommendedPlanData,
          userData: updateUserData,
          calculateEditHook: handleEditCalculateForm(handleFormModalState),
          calculateEnquiryHook: handleEnquiryForm(updateStatus),
          confirmationForm: {
            ...enquiryFormProps.confirmationForm,
            calculateFormValues: payload?.data?.formValues,
          },
        },
        calculateProductName: productName,
        dictionary,
        plans,
      };

      sessionStorage.setItem(
        "calculatedData",
        JSON.stringify({ ...cloneDeep(storeData), locale: language.locale })
      );
      setSessionData(
        JSON.stringify({ ...cloneDeep(storeData), locale: language.locale })
      );
      setEnquiryFormProps({ ...cloneDeep(storeData) }.calculateFormProps);
    }

    setFormModalState(false);

    if (payload?.data.type === "recommender_plan") {
      setFormModalState(true);
    }
  };

  const handleFormModalState = (state: boolean) => {
    if (!state) {
      setUserData(defaultUserData);
      sessionStorage.removeItem("calculatedData");
      setSessionData("{}");
      setEnquiryFormProps({});
      setFormModalState(state);
    } else {
      handleEditClick();
    }
  };

  const handleEditClick = () => {
    if (sessionStorage.getItem("calculatedData") !== "") {
      const stored = JSON.parse(
        sessionStorage.getItem("calculatedData") || "{}"
      );

      setEnquiryFormProps({
        ...cloneDeep(stored.calculateFormProps),
        productDetails: {
          ...stored.productDetails,
          initializer: "bookAnAgent",
          type:
            stored.calculateFormProps?.productDetails?.type === "recommender"
              ? "recommender_plan"
              : "calculate",
        },
      });

      setFormModalState(true);
    }
  };

  const mapAdditionalInfos = (payload: any) => {
    delete payload?.data?.user?.profile?.contact?.email?.templateUrl;
    if (payload && payload.data?.user?.profile?.contact?.email?.parameters) {
      payload.data.user.profile.contact.email.parameters.dTCCampaignId =
        additionalInfos.dTCCampaignId || "";
      payload.data.user.profile.contact.email.parameters.requireULLicense =
        additionalInfos.requireULLicense || "";
      payload.data.user.profile.contact.email.parameters.expectedInsuranceType =
        payload.data.user.profile.contact.email.parameters
          .expectedInsuranceType ||
        additionalInfos.expectedInsuranceType ||
        "";

      if (!!payload.data?.user?.lead) {
        payload.data.user.lead.dTCCampaignId =
          additionalInfos.dTCCampaignId || "";
        payload.data.user.lead.requireULLicense =
          additionalInfos.requireULLicense || "";
        payload.data.user.lead.expectedInsuranceType =
          payload.data.user.lead.expectedInsuranceType ||
          additionalInfos.expectedInsuranceType ||
          "";
      }
    }
    if (
      router.query &&
      Object.keys(router.query).length > 0 &&
      props?.value?.publicConfigurations?.publicCountryCode === "ph" &&
      payload?.data?.user?.profile?.contact?.email?.parameters &&
      payload?.data?.user?.lead
    ) {
      let keyMaps = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
        "utm_id",
      ];
      Object.keys(router.query).forEach((key) => {
        if (keyMaps.indexOf(key) >= 0) {
          payload.data.user.profile.contact.email.parameters[key] =
            router.query[key];
          payload.data.user.lead[key] = router.query[key];
        }
      });
    }
    // For VN only
    if (
      props?.value?.publicConfigurations?.publicCountryCode === "vn" &&
      payload &&
      !!payload.data?.user?.lead
    ) {
      payload.data.user.lead.existingCustomer = !!(
        ["yes", "có"].indexOf(
          payload.data?.user?.profile?.contact?.email?.parameters?.existingCustomer?.toLowerCase()
        ) >= 0
      );
      payload.data.user.lead.homeProvince =
        payload.data?.user?.profile?.contact?.email?.parameters?.homeProvince ||
        "";
    }
    if (payload && payload.data && productId !== "") {
      payload.data.productId = productId;
    }
    if (payload && payload.data && productPlanId !== "") {
      payload.data.productPlanId = productPlanId;
    }
    return payload;
  };

  const mappingDropdownValues = (payload: any) => {
    if (!!enquiryFormProps.form.sitecore.route.placeholders["jss-main"]) {
      const fields =
        enquiryFormProps.form.sitecore.route.placeholders["jss-main"][0]
          ?.fields;
      if (payload && payload.data && payload.data.user) {
        Object.keys(payload.data.user.lead).forEach((key) => {
          let matchItem: any = [];
          fields.items.forEach((item) => {
            const idx = item.items.findIndex((childItem) => {
              return (
                childItem.fields.apiName?.fieldType === "Droplink" &&
                childItem.fields.apiName?.fields?.key?.value === key &&
                childItem.items?.length > 0
              );
            });
            if (idx >= 0 && item.items[idx]) {
              matchItem = item.items[idx];
            }
          });
          if (matchItem && matchItem.items?.length > 0) {
            let dataField: any = matchItem.items.find((i) => {
              return i?.fields?.value?.value === payload.data.user.lead?.[key];
            });
            if (dataField && payload.data.user.lead) {
              payload.data.user.lead[key] = dataField?.fields?.key?.value;
            }
          }
        });
      }
    }
  };

  return (
    <LeadFormContextProvider>
      <EnquiryContext.Provider
        value={{
          updateStatus,
          calculateProduct,
          handleFormModalState,
          userData,
          enquiryFormProps,
          productName,
        }}
      >
        {props && props.children}
        <NextGenFormsHoc
          formModalState={formModalState}
          updateFormModalState={setFormModalState}
          resetAPIStatus={() => setApiStatus(defaultAPIStatus)}
          submitAction={onSubmit}
          {...cloneDeep(enquiryFormProps)}
          apiStatus={apiStatus}
          dictionary={dictionary}
          disableFormSubmittedValues={false}
          isFromCalculation={isProductPlan}
        />
      </EnquiryContext.Provider>
    </LeadFormContextProvider>
  );
}
