import { Language } from "i18n/types";

function getSumAssuredDisplayText(calculatorForm: any) {
  if (
    (calculatorForm?.sumAssured?.currencyPosition || "").toLowerCase() ==
    "suffix"
  ) {
    return `${calculatorForm?.sumAssured?.options?.[0]?.toLocaleString()}${
      calculatorForm?.sumAssured?.currency
    }`;
  }

  return `${
    calculatorForm?.sumAssured?.currency
  } ${calculatorForm?.sumAssured?.options?.[0]?.toLocaleString()}`;
}

export function getCallbackCalculatorForm(
  calculatorForm: any,
  language: Language,
  type: string
) {
  calculatorForm?.sumAssured?.options?.sort((a: any, b: any) => {
    return a > b ? 1 : a < b ? -1 : 0;
  });

  return {
    sitecoreContext: {
      language: language.languageCode || "en",
      languageCode: language.languageCode || "en",
      defaultLanguage: "en",
    },
    site: {
      name: "fwd-th",
    },
    apiStatus: {
      loading: false,
      apiStatus: null,
      errorCode: "",
      failCounter: 0,
    },
    productDetails: {
      initializer: "bookAnAgent",
      type,
    },
    form: {
      sitecore: {
        context: {
          pageEditing: false,
          site: {
            name: "fwd-hk",
          },
          pageState: "normal",
          language: "en",
          languageCode: "en",
          defaultLanguage: "th-TH",
          siteSettings: {
            productListPage: "/products/",
            splashPage: "",
            globalMetaText: "FWD Hong Kong",
            cookieMessage:
              '<p>We use cookies to give you the best possible experience on our website. By continuing to browse this site, you give consent for cookies to be used. </p>\n<p>For more details please read our <a href="~/link.aspx?_id=DCD61657FA884252B0580B7EBE7F06F1&amp;_z=z">Cookie Policy</a>.</p>',
            cookieTimeStamp: "10",
            currencyPlacement: "BeforeNumericals",
            currencyWhitespace: false,
            currencySeparator: {
              value: {},
            },
            currencyLabel: {
              value: {
                USD: "US$",
                EUR: "EUR",
                HKD: "HKD",
              },
            },
            splashPageUpdatedDuration: "10",
            splashPageUpdatedDate: "",
            faviconImageUrl:
              "https://www.fwd.com.hk/-/media/global/images/icons/favicon.ico?rev=51cce1bfcfb347b3b2fc7d304180401d",
            productErrorPictogramUrl:
              "https://www.fwd.com.hk/-/media/pictograms/no-result-48px2x.png?rev=92f0c2a020e145e7a186408b6f8a8c09",
            dateFormat: "D MMMM YYYY",
            enablePlanCardSharePostCalc: "",
            sessionTimeout: "20",
            loaderTimeout: "20",
            gAID: "GTM-N6B3G67",
            gTMID: "GTM-N6B3G67",
            gtmParamters: [],
            hostName: "https://www.fwd.com.hk",
            enableBackToTopButton: 1,
            dialogSessionTimeout: "20",
            productFallbackCTA: {
              value: {
                href: "/forms-list/product-enquiry-form",
                text: "Enquire",
                anchor: "Enquire",
                linkType: "form",
                title: "Enquire",
                queryString: "enquiry",
                id: "{F2728281-FA5C-41CB-B168-773E0C78197F}",
                gaLabel: "Enquiry",
              },
            },
            brightcoveAccountID: "",
            languageIcon: {
              key: "language-globe",
              value: "language-globe",
            },
            enableMultipleNotifications: true,
            hidePrimaryLanguage: true,
            currencyGALabelName: "",
          },
          share: {
            socialApps: [],
          },
          siteNotifications: {
            notifications: [],
          },
          dialogModals: {
            modalList: [],
          },
          disclosurePopups: {
            popupList: [],
          },
          localizedPages: [
            {
              "en-hk": "/en/forms-list/general-enquiry-form",
            },
            {
              "zh-Hant-hk": "/forms-list/general-enquiry-form",
            },
          ],
          isRequestFromExternalSource: true,
          availableLanguages: ["en", "th"],
        },
        route: {
          fields: {
            apiList: [
              {
                fields: {
                  apiName: {
                    value: "Calculate",
                  },
                  value: {
                    value: calculatorForm?.submissionEndpoint,
                  },
                  key: {
                    value: calculatorForm?.authenticationKey,
                  },
                },
              },
            ],
          },
          placeholders: {
            "jss-main": [
              {
                componentName: "Forms",
                params: {
                  formType: "conversational",
                },
                fields: {
                  items: [
                    {
                      id: "fcc3e054-7b8b-498b-9e57-d3bfbb8b867a",
                      name: "splashScreen",
                      displayName: "splashScreen",
                      parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
                      fields: {
                        gaLabel: {
                          value: "",
                        },
                        backgroundColor: {
                          id: "b47928d6-98fc-4b29-8d0f-586ab477286e",
                          fieldType: "Droplink",
                          fieldName: "backgroundColor",
                          url: "/sitecore/content/fwd/global/settings/list/colors/orangewhite",
                          fields: {
                            value: {
                              value: "orange-white",
                            },
                            key: {
                              value: "OrangeWhite",
                            },
                          },
                        },
                        "Css Class": {
                          value: "splashScreenPage darkMode",
                        },
                        isHidden: {
                          value: false,
                        },
                        HelpText: {
                          value: "",
                        },
                        GeneralError: {
                          value: "",
                        },
                        SpecificError: {
                          value: "",
                        },
                        apiName: null,
                        "Field Type": {
                          id: "d819b43e-c136-4392-9393-8be7fce32f5e",
                          fieldType: "Droptree",
                          fieldName: "Field Type",
                          url: "/sitecore/system/settings/forms/field-types/structure/page",
                          fields: {
                            BackgroundColor: {
                              value: "Eggplant",
                            },
                            Icon: {
                              value: "OfficeWhite/32x32/window_split_ver.png",
                            },
                            IsDisabled: {
                              value: false,
                            },
                            SpritePosition: {
                              value: "",
                            },
                            "Field Template": {
                              id: "cfee7b51-8505-45ce-b843-9358f827df87",
                              fieldType: "Droptree",
                              fieldName: "Field Template",
                              url: "/sitecore/templates/system/forms/fields/page",
                              fields: {},
                            },
                            "Allowed Validations": [],
                            "Model Type": {
                              value:
                                "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
                            },
                            "Property Editor": null,
                            "View Path": {
                              value: "FieldTemplates/Page",
                            },
                          },
                        },
                        Conditions: {
                          value: '{"fieldConditions":[]}',
                        },
                        "Field Key": {
                          value: "3F83046E6287400389197ECF35DAA74D",
                        },
                        "Collapsed by Default": {
                          value: false,
                        },
                      },
                      items: [
                        {
                          id: "0d83cfc8-883b-4777-924f-bfbea02901e0",
                          name: "introText",
                          displayName: "introText",
                          parentId: "fcc3e054-7b8b-498b-9e57-d3bfbb8b867a",
                          fields: {
                            "Html Tag": {
                              value: "p",
                            },
                            Text: {
                              value: calculatorForm?.introductionBlock?.heading,
                            },
                            "Css Class": {
                              value: "intro",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Field Type": {
                              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/basic/text",
                              fields: {
                                BackgroundColor: {
                                  value: "Sky",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/document_text.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/text",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Text",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "F0B323C89D9942BFA7DA017E69B864AB",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                        {
                          id: "4ef4127e-b7f0-4eaa-a49f-5e3081db18f4",
                          name: "Text",
                          displayName: "Text",
                          parentId: "fcc3e054-7b8b-498b-9e57-d3bfbb8b867a",
                          fields: {
                            "Html Tag": {
                              value: "p",
                            },
                            Text: {
                              value:
                                calculatorForm?.introductionBlock?.description,
                            },
                            "Css Class": {
                              value: "splashScreenDisclaimer",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Field Type": {
                              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/basic/text",
                              fields: {
                                BackgroundColor: {
                                  value: "Sky",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/document_text.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/text",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Text",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "89B5A6C3C7D84BCEAB2F22A622F968AE",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                        {
                          id: "404e7cce-6c5e-4d2d-b2d9-d71fe61ef1c1",
                          name: "splashScreenClick",
                          displayName: "splashScreenClick",
                          parentId: "fcc3e054-7b8b-498b-9e57-d3bfbb8b867a",
                          fields: {
                            "Navigation Step": {
                              value: "1",
                            },
                            emailTemplateLink: {
                              value: "",
                            },
                            "Css Class": {
                              value: "",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Label Css Class": {
                              value: "",
                            },
                            Title: {
                              value: calculatorForm?.startButtonText,
                            },
                            "Field Type": {
                              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                              fields: {
                                BackgroundColor: {
                                  value: "Eggplant",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/button.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/button",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Button",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "12A1320BA02240D6A3DAA22D4CD38950",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                      ],
                    },
                    {
                      id: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
                      name: "taskPage",
                      displayName: "taskPage",
                      parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
                      fields: {
                        gaLabel: {
                          value: "",
                        },
                        backgroundColor: {
                          id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
                          fieldType: "Droplink",
                          fieldName: "backgroundColor",
                          url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
                          fields: {
                            value: {
                              value: "white-orange",
                            },
                            key: {
                              value: "WhiteOrange",
                            },
                          },
                        },
                        "Css Class": {
                          value: "interestedPage",
                        },
                        isHidden: {
                          value: false,
                        },
                        HelpText: {
                          value: "",
                        },
                        GeneralError: {
                          value: "",
                        },
                        SpecificError: {
                          value: "",
                        },
                        apiName: null,
                        "Field Type": {
                          id: "d819b43e-c136-4392-9393-8be7fce32f5e",
                          fieldType: "Droptree",
                          fieldName: "Field Type",
                          url: "/sitecore/system/settings/forms/field-types/structure/page",
                          fields: {
                            BackgroundColor: {
                              value: "Eggplant",
                            },
                            Icon: {
                              value: "OfficeWhite/32x32/window_split_ver.png",
                            },
                            IsDisabled: {
                              value: false,
                            },
                            SpritePosition: {
                              value: "",
                            },
                            "Field Template": {
                              id: "cfee7b51-8505-45ce-b843-9358f827df87",
                              fieldType: "Droptree",
                              fieldName: "Field Template",
                              url: "/sitecore/templates/system/forms/fields/page",
                              fields: {},
                            },
                            "Allowed Validations": [],
                            "Model Type": {
                              value:
                                "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
                            },
                            "Property Editor": null,
                            "View Path": {
                              value: "FieldTemplates/Page",
                            },
                          },
                        },
                        Conditions: {
                          value: '{"fieldConditions":[]}',
                        },
                        "Field Key": {
                          value: "04B6B0BE40774539912EB6E4268008B9",
                        },
                        "Collapsed by Default": {
                          value: false,
                        },
                      },
                      items: [
                        {
                          id: "263046a7-a1a9-456e-99fe-a5713db6491c",
                          name: "Text",
                          displayName: "Text",
                          parentId: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
                          fields: {
                            "Html Tag": {
                              value: "p",
                            },
                            Text: {
                              value:
                                calculatorForm?.selectCategoryBlock?.prefix ||
                                "",
                            },
                            "Css Class": {
                              value: "block",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Field Type": {
                              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/basic/text",
                              fields: {
                                BackgroundColor: {
                                  value: "Sky",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/document_text.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/text",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Text",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "B5C635B295AC460DB7A7644CD8A4B416",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                        {
                          id: "06bfc978-15c0-4cb0-9d7c-068f75061df3",
                          name: "taskOptionsList",
                          displayName: "Call Back Reasons",
                          parentId: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
                          fields: {
                            Datasource: {
                              id: "73285402-c0d0-42ec-828b-259642fa1510",
                              fieldType: "Droptree",
                              fieldName: "Datasource",
                              url: "/content/forms/tasks",
                              fields: {},
                            },
                            "Default Selection": {
                              value: "",
                            },
                            "Display Field Name": {
                              value: "__Display name",
                            },
                            "Is Dynamic": {
                              value: true,
                            },
                            "Value Field Name": {
                              value: "value",
                            },
                            "Css Class": {
                              value: "block",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "02e6f52d-421f-4179-b9f6-9cc0863cc4da",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/genderCd",
                              fields: {
                                value: {
                                  value: "gender",
                                },
                                key: {
                                  value: "gender",
                                },
                              },
                            },
                            "Label Css Class": {
                              value: "",
                            },
                            Title: {
                              value: "",
                            },
                            Required: {
                              value: true,
                            },
                            Validations: [],
                            "Is Tracking Enabled": {
                              value: true,
                            },
                            "Allow Save": {
                              value: true,
                            },
                            "Value Provider Parameters": {
                              value: "",
                            },
                            "Value Provider": null,
                            "Field Type": {
                              id: "edbd38a8-1ae9-42ec-8ccd-f5b0e2998b4f",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/lists/radio-button-list",
                              fields: {
                                BackgroundColor: {
                                  value: "Grass",
                                },
                                Icon: {
                                  value:
                                    "OfficeWhite/32x32/radio_button_group.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "5b672865-55d2-413e-b699-fdfc7e732ccf",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/list",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomRadioButtonListViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/RadioButtonList",
                                },
                              },
                            },
                            Conditions: {
                              value:
                                '{"fieldConditions":[{"matchTypeId":"{365C94DA-C1CD-4783-A91D-0D17A16C7117}","conditions":[{"fieldId":"93BF0439473B468BBC93BC561328CA9D","operatorId":"{49F47E77-E8C5-46F9-BF39-78D6B0D40B48}","value":"Book time with an agent"}],"actions":[{"fieldId":"70CF13F315E14103A03450E07FEA39C1","actionTypeId":"{AAE07A52-46A4-49EF-98B0-C2595BAC2382}","value":null},{"fieldId":"D9B947C796E4429A9649870470347AE9","actionTypeId":"{AAE07A52-46A4-49EF-98B0-C2595BAC2382}","value":null},{"fieldId":"7BB73BAD1EEC4F309AB7046C2FFF2ED2","actionTypeId":"{AAE07A52-46A4-49EF-98B0-C2595BAC2382}","value":null}]},{"matchTypeId":"{365C94DA-C1CD-4783-A91D-0D17A16C7117}","conditions":[{"fieldId":"93BF0439473B468BBC93BC561328CA9D","operatorId":"{1D38B217-A2EE-4E7B-B6ED-13E751462FEB}","value":"Book time with an agent"}],"actions":[{"fieldId":"0B059E90D26E41B2B35BE476FB35A168","actionTypeId":"{AAE07A52-46A4-49EF-98B0-C2595BAC2382}","value":null},{"fieldId":"D9G917C796E4429A9649870470347ABE","actionTypeId":"{AAE07A52-46A4-49EF-98B0-C2595BAC2382}","value":null},{"fieldId":"D9B947C796E4429A9649870470347ABE","actionTypeId":"{AAE07A52-46A4-49EF-98B0-C2595BAC2382}","value":null}, {"fieldId":"915D7E632E374A2AA094ABE3D95213BF","actionTypeId":"{AAE07A52-46A4-49EF-98B0-C2595BAC2382}","value":null}]}]}',
                            },
                            "Field Key": {
                              value: "93BF0439473B468BBC93BC561328CA9D",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: calculatorForm?.selectCategoryBlock?.nameValue,
                        },
                        {
                          id: "34928375-a16b-4d42-9186-4b7df0737685",
                          name: "ToolTip",
                          displayName: "ToolTip",
                          parentId: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",
                          fields: {
                            toolTipText: {
                              value:
                                calculatorForm?.selectCategoryBlock
                                  ?.questionHint,
                            },
                            label: {
                              value:
                                calculatorForm?.selectCategoryBlock
                                  ?.questionText,
                            },
                            "Css Class": {
                              value: "tool-tip",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: null,
                            "Is Tracking Enabled": {
                              value: true,
                            },
                            "Field Type": {
                              id: "fff6a7f6-db43-49f4-80aa-519af8ede3c5",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/basic/tooltip",
                              fields: {
                                BackgroundColor: {
                                  value: "Sky",
                                },
                                Icon: {
                                  value: "Office/32x32/question.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "1edd4140-c0f7-4b64-aaf6-dd99abd590de",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/tooltip",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomToolTipViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/ToolTip",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "08ACA529BB6048C09ADF023D64D0EDAD",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                        {
                          id: "33530b5c-455f-4432-a7e6-f6255c35b210",
                          name: "tasksNextButtonClick",
                          displayName: "Next Page",
                          parentId: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
                          fields: {
                            "Navigation Step": {
                              value: "1",
                            },
                            emailTemplateLink: {
                              value: "",
                            },
                            "Css Class": {
                              value: "next-button",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Label Css Class": {
                              value: "",
                            },
                            Title: {
                              value: calculatorForm?.nextButtonText,
                            },
                            "Field Type": {
                              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                              fields: {
                                BackgroundColor: {
                                  value: "Eggplant",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/button.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/button",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Button",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "0B059E90D26E41B2B35BE476FB35A168",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [
                            {
                              id: "e9f1cad0-f49d-4ce0-a152-63ea6378ec62",
                              name: "Redirect to Page",
                              displayName: "Redirect to Page",
                              parentId: "33530b5c-455f-4432-a7e6-f6255c35b210",
                              fields: {
                                Description: {
                                  value: "subTaskPage",
                                },
                                Parameters: {
                                  value:
                                    '{"referenceId":"{FA9A0DDD-F620-436E-B1A4-976868988C50}"}',
                                },
                                "Submit Action": {
                                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                                  fieldType: "Droptree",
                                  fieldName: "Submit Action",
                                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                                  fields: {
                                    Editor: null,
                                    "Error Message": {
                                      value: "Redirect to page failed!",
                                    },
                                    "Model Type": {
                                      value:
                                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                                    },
                                  },
                                },
                              },
                              items: [],
                            },
                          ],
                        },
                        {
                          id: "422c12cf-3bfc-417a-88e2-0055490f88f7",
                          name: "otherOptionNextClick",
                          displayName: "otherOptionNextClick",
                          parentId: "f34fb0d2-3fc0-4a9a-8eb1-88717eb6ddce",
                          fields: {
                            "Navigation Step": {
                              value: "1",
                            },
                            emailTemplateLink: {
                              value: "",
                            },
                            "Css Class": {
                              value: "next-button",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Label Css Class": {
                              value: "",
                            },
                            Title: {
                              value: calculatorForm?.nextButtonText,
                            },
                            "Field Type": {
                              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                              fields: {
                                BackgroundColor: {
                                  value: "Eggplant",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/button.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/button",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Button",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "70CF13F315E14103A03450E07FEA39C1",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [
                            {
                              id: "dc0b68b8-097c-424a-bd32-61691ad23039",
                              name: "Redirect to Page",
                              displayName: "Redirect to Page",
                              parentId: "422c12cf-3bfc-417a-88e2-0055490f88f7",
                              fields: {
                                Description: {
                                  value: "Name",
                                },
                                Parameters: {
                                  value:
                                    '{"referenceId":"{3cb1e880-b628-4ef4-89f8-80e7bcbddc7d}"}',
                                },
                                "Submit Action": {
                                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                                  fieldType: "Droptree",
                                  fieldName: "Submit Action",
                                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                                  fields: {
                                    Editor: null,
                                    "Error Message": {
                                      value: "Redirect to page failed!",
                                    },
                                    "Model Type": {
                                      value:
                                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                                    },
                                  },
                                },
                              },
                              items: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      id: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",
                      name: "dobPage",
                      displayName: "dobPage",
                      parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
                      fields: {
                        gaLabel: {
                          value: "",
                        },
                        backgroundColor: {
                          id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
                          fieldType: "Droplink",
                          fieldName: "backgroundColor",
                          url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
                          fields: {
                            value: {
                              value: "white-orange",
                            },
                            key: {
                              value: "WhiteOrange",
                            },
                          },
                        },
                        "Css Class": {
                          value: "dobPage",
                        },
                        isHidden: {
                          value: false,
                        },
                        HelpText: {
                          value: "",
                        },
                        GeneralError: {
                          value: "",
                        },
                        SpecificError: {
                          value: "",
                        },
                        apiName: null,
                        "Field Type": {
                          id: "d819b43e-c136-4392-9393-8be7fce32f5e",
                          fieldType: "Droptree",
                          fieldName: "Field Type",
                          url: "/sitecore/system/settings/forms/field-types/structure/page",
                          fields: {
                            BackgroundColor: {
                              value: "Eggplant",
                            },
                            Icon: {
                              value: "OfficeWhite/32x32/window_split_ver.png",
                            },
                            IsDisabled: {
                              value: false,
                            },
                            SpritePosition: {
                              value: "",
                            },
                            "Field Template": {
                              id: "cfee7b51-8505-45ce-b843-9358f827df87",
                              fieldType: "Droptree",
                              fieldName: "Field Template",
                              url: "/sitecore/templates/system/forms/fields/page",
                              fields: {},
                            },
                            "Allowed Validations": [],
                            "Model Type": {
                              value:
                                "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
                            },
                            "Property Editor": null,
                            "View Path": {
                              value: "FieldTemplates/Page",
                            },
                          },
                        },
                        Conditions: {
                          value: '{"fieldConditions":[]}',
                        },
                        "Field Key": {
                          value: "83F59508E70E42938C9C8BC6626295FC",
                        },
                        "Collapsed by Default": {
                          value: false,
                        },
                      },
                      items: [
                        {
                          id: "093e9da5-c485-4491-8c6e-86b89bac974e",
                          name: "Text",
                          displayName: "Text",
                          parentId: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",
                          fields: {
                            "Html Tag": {
                              value: "p",
                            },
                            Text: {
                              value: calculatorForm?.dobBlock?.title,
                            },
                            "Css Class": {
                              value: "block",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: null,
                            "Field Type": {
                              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/basic/text",
                              fields: {
                                BackgroundColor: {
                                  value: "Sky",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/document_text.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/text",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Text",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "9572D218437248E980F66B54BF9FAEA6",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                        {
                          id: "c7a9a0a1-9f24-47aa-b654-277e8f72debf",
                          name: "dob",
                          displayName: {
                            day: calculatorForm?.dobBlock?.dayText,
                            month: calculatorForm?.dobBlock?.monthText,
                            year: calculatorForm?.dobBlock?.yearText,
                          },
                          parentId: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",

                          fields: {
                            "Show Empty Item": {
                              value: true,
                            },
                            isAutoSuggest: {
                              value: false,
                            },
                            Datasource: {
                              id: "18de3e80-78b9-48b3-a1f1-aedf03565306",
                              fieldType: "Droptree",
                              fieldName: "Datasource",
                              url: "/content/forms/contactdaylist-general-enquiry",
                              fields: {},
                            },
                            Text: {
                              value: "Demo 1",
                            },
                            "Default Selection": {
                              value: "1",
                            },
                            "Display Field Name": {
                              value: "value",
                            },
                            "Is Dynamic": {
                              value: true,
                            },
                            "Value Field Name": {
                              value: "key",
                            },
                            "Css Class": {
                              value: "inline",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: calculatorForm?.dobBlock?.helpText,
                            },
                            GeneralError: {
                              value:
                                calculatorForm?.dobBlock?.validationMessage,
                            },
                            SpecificError: {
                              value: calculatorForm?.dobBlock?.premiumError,
                            },
                            apiName: {
                              id: "58b136a7-764d-48c7-a721-a53d8fa01074",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/dob",
                              fields: {
                                value: {
                                  value: "dob",
                                },
                                key: {
                                  value: "dob",
                                },
                              },
                            },
                            minimumYear: {
                              value: calculatorForm?.dobBlock?.fromYear,
                            },
                            maximumYear: {
                              value: calculatorForm?.dobBlock?.toYear,
                            },
                            rangeItem: {
                              productAgeRange: {
                                productMinAge:
                                  calculatorForm?.dobBlock?.minYear,
                                productMaxAge:
                                  calculatorForm?.dobBlock?.maxYear,
                              },
                            },
                            "Label Css Class": {
                              value: "",
                            },
                            Title: {
                              value: "",
                            },
                            Required: {
                              value: true,
                            },
                            Validations: [],
                            "Is Tracking Enabled": {
                              value: true,
                            },
                            "Allow Save": {
                              value: true,
                            },
                            "Value Provider Parameters": {
                              value: "",
                            },
                            "Value Provider": null,
                            "Collapsed by Default": {
                              value: false,
                            },
                            "Field Type": {
                              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/dob",
                              fields: {
                                BackgroundColor: {
                                  value: "Grass",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/drop_down_list.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/DropdownList",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "dob",
                            },
                          },
                          items: [],
                        },
                        {
                          id: "33928375-a16b-4d42-9186-4b7df0737685",
                          name: "ToolTip",
                          displayName: "ToolTip",
                          parentId: "3cb1e880-b628-4ef4-89f8-80e7bcbddc7d",
                          fields: {
                            toolTipText: {
                              value: calculatorForm?.dobBlock?.questionHint,
                            },
                            label: {
                              value: calculatorForm?.dobBlock?.questionText,
                            },
                            "Css Class": {
                              value: "tool-tip",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: null,
                            "Is Tracking Enabled": {
                              value: true,
                            },
                            "Field Type": {
                              id: "fff6a7f6-db43-49f4-80aa-519af8ede3c5",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/basic/tooltip",
                              fields: {
                                BackgroundColor: {
                                  value: "Sky",
                                },
                                Icon: {
                                  value: "Office/32x32/question.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "1edd4140-c0f7-4b64-aaf6-dd99abd590de",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/tooltip",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomToolTipViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/ToolTip",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "08ACA529BB6048C09ADF023D64D0EDAD",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                        {
                          id: "c1f16a5c-5083-4418-a39f-61f20efdb459",
                          name: "dobButtonNextClick",
                          displayName: "dobButtonNextClick",
                          parentId: "fa9a0ddd-f620-436e-b1a4-976868988c50",
                          fields: {
                            "Navigation Step": {
                              value: "1",
                            },
                            emailTemplateLink: {
                              value: "",
                            },
                            "Css Class": {
                              value: "next-button",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Label Css Class": {
                              value: "",
                            },
                            Title: {
                              value: calculatorForm?.nextButtonText,
                            },
                            "Field Type": {
                              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                              fields: {
                                BackgroundColor: {
                                  value: "Eggplant",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/button.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/button",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Button",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "63FEB8F928EA4CAFB690CB395F0667A5",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [
                            {
                              id: "e81e0d31-715a-48a9-9ac9-4d64ac42f4d7",
                              name: "Redirect to Page",
                              displayName: "Redirect to Page",
                              parentId: "c1f16a5c-5083-4418-a39f-61f20efdb459",
                              fields: {
                                Description: {
                                  value: "Name",
                                },
                                Parameters: {
                                  value:
                                    '{"referenceId":"{ce204667-409d-401f-9585-c28ee8446b9e}"}',
                                },
                                "Submit Action": {
                                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                                  fieldType: "Droptree",
                                  fieldName: "Submit Action",
                                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                                  fields: {
                                    Editor: null,
                                    "Error Message": {
                                      value: "Redirect to page failed!",
                                    },
                                    "Model Type": {
                                      value:
                                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                                    },
                                  },
                                },
                              },
                              items: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      id: "ce204667-409d-401f-9585-c28ee8446b9e",
                      name: "sumAssured",
                      displayName: "Sum Assured",
                      parentId: "57ec00df-f75a-4645-a4f1-a830be98de45",
                      fields: {
                        gaLabel: {
                          value: "",
                        },
                        backgroundColor: {
                          id: "cbdc6265-d09f-4982-981a-bcc8f118f36f",
                          fieldType: "Droplink",
                          fieldName: "backgroundColor",
                          url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
                          fields: {
                            value: {
                              value: "white-orange",
                            },
                            key: {
                              value: "WhiteOrange",
                            },
                          },
                        },
                        "Css Class": {
                          value: "sumAssured",
                        },
                        isHidden: {
                          value: false,
                        },
                        HelpText: {
                          value: "",
                        },
                        GeneralError: {
                          value: "",
                        },
                        SpecificError: {
                          value: "",
                        },
                        apiName: null,
                        "Field Type": {
                          id: "d819b43e-c136-4392-9393-8be7fce32f5e",
                          fieldType: "Droptree",
                          fieldName: "Field Type",
                          url: "/sitecore/system/settings/forms/field-types/structure/page",
                          fields: {
                            BackgroundColor: {
                              value: "Eggplant",
                            },
                            Icon: {
                              value: "OfficeWhite/32x32/window_split_ver.png",
                            },
                            IsDisabled: {
                              value: false,
                            },
                            SpritePosition: {
                              value: "",
                            },
                            "Field Template": {
                              id: "cfee7b51-8505-45ce-b843-9358f827df87",
                              fieldType: "Droptree",
                              fieldName: "Field Template",
                              url: "/sitecore/templates/system/forms/fields/page",
                              fields: {},
                            },
                            "Allowed Validations": [],
                            "Model Type": {
                              value:
                                "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
                            },
                            "Property Editor": null,
                            "View Path": {
                              value: "FieldTemplates/Page",
                            },
                          },
                        },
                        Conditions: {
                          value: '{"fieldConditions":[]}',
                        },
                        "Field Key": {
                          value: "9E94EEE6AE21451DBE91C3B2B376849A",
                        },
                        "Collapsed by Default": {
                          value: false,
                        },
                      },
                      items: [
                        {
                          id: "1789f54d-33c3-4045-baf6-bf2f857712ae",
                          name: "Text",
                          displayName: "Text",
                          parentId: "ce204667-409d-401f-9585-c28ee8446b9e",
                          fields: {
                            "Html Tag": {
                              value: "p",
                            },
                            Text: {
                              value: calculatorForm?.sumAssured?.prefix,
                            },
                            "Css Class": {
                              value: "inline",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Field Type": {
                              id: "983bfa5f-c6b6-4aee-a3bb-46b95d52e7df",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/basic/text",
                              fields: {
                                BackgroundColor: {
                                  value: "Sky",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/document_text.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/text",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Text",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "04BB8D346D4D4667B6D98295CE78AB93",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                        {
                          id: "a8a831c4-ce45-4545-adb2-ed75a15688c8",
                          name: "sumAssuredDropDown",
                          displayName: "Sum Assured",
                          parentId: "ce204667-409d-401f-9585-c28ee8446b9e",
                          fields: {
                            "Show Empty Item": {
                              value: true,
                            },
                            isAutoSuggest: {
                              value: false,
                            },
                            Datasource: {
                              id: "18de3e80-78b9-48b3-a1f1-aedf03565306",
                              fieldType: "Droptree",
                              fieldName: "Datasource",
                              url: "/content/forms/contactdaylist-general-enquiry",
                              fields: {},
                            },

                            "Default Selection": {
                              value: "",
                            },
                            "Display Field Name": {
                              value: "value",
                            },
                            "Is Dynamic": {
                              value: true,
                            },
                            "Value Field Name": {
                              value: "key",
                            },
                            "Css Class": {
                              value: "inline",
                            },
                            dotNumber: {
                              value:
                                calculatorForm?.sumAssured?.dotNumber || false,
                            },
                            dotNumberInConfirmPage: {
                              value:
                                calculatorForm?.sumAssured
                                  ?.dotNumberInConfirmPage || false,
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpTextSelect: {
                              value: calculatorForm?.sumAssured?.helpTextSelect,
                            },
                            HelpTextSelectSeparator: {
                              value:
                                calculatorForm?.sumAssured
                                  ?.helpTextSelectSeparator,
                            },
                            HelpTextRange: {
                              value: calculatorForm?.sumAssured?.helpTextRange,
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "58b136a7-764d-48c7-a721-a53d8fa01074",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/sumAssured",
                              fields: {
                                value: {
                                  value: "sumAssured",
                                },
                                key: {
                                  value: "sumAssured",
                                },
                              },
                            },
                            "Label Css Class": {
                              value: "",
                            },
                            Title: {
                              value: getSumAssuredDisplayText(calculatorForm),
                            },
                            "Placeholder Text": {
                              value: `${calculatorForm?.sumAssured?.options?.[0]}`,
                            },
                            Currency: {
                              value: calculatorForm?.sumAssured?.currency,
                            },
                            CurrencyPosition: {
                              value:
                                calculatorForm?.sumAssured?.currencyPosition,
                            },
                            Amount: {
                              value: calculatorForm?.sumAssured?.amount,
                            },
                            LimitAmount: {
                              value: calculatorForm?.sumAssured?.limitAmount,
                            },
                            LimitAge: {
                              value: calculatorForm?.sumAssured?.limitAge,
                            },
                            DefaultValue: {
                              value:
                                calculatorForm?.sumAssured?.rangeDefaultValue,
                            },
                            Required: {
                              value: true,
                            },
                            Validations: [],
                            "Is Tracking Enabled": {
                              value: true,
                            },
                            "Allow Save": {
                              value: true,
                            },
                            "Value Provider Parameters": {
                              value: "",
                            },
                            "Value Provider": null,
                            "Collapsed by Default": {
                              value: false,
                            },
                            "Field Type": {
                              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: calculatorForm?.sumAssured?.type,
                              fields: {
                                BackgroundColor: {
                                  value: "Grass",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/drop_down_list.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/DropdownList",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "F388ECF875174833A4D66E2E2642B15A",
                            },
                          },
                          items:
                            calculatorForm?.sumAssured?.options?.map(
                              (option: number) => {
                                if (
                                  (
                                    calculatorForm?.sumAssured
                                      ?.currencyPosition || ""
                                  ).toLowerCase() == "suffix"
                                ) {
                                  return `${option.toLocaleString()} ${
                                    calculatorForm?.sumAssured?.currency
                                  }`;
                                }

                                return `${
                                  calculatorForm?.sumAssured?.currency
                                } ${option.toLocaleString()}`;
                              }
                            ) || [],
                        },
                        {
                          id: "873e3fe1-8f2a-4176-9831-fb7d00cba3c9",
                          name: "ToolTip",
                          displayName: "ToolTip",
                          parentId: "ce204667-409d-401f-9585-c28ee8446b9e",
                          fields: {
                            toolTipText: {
                              value: calculatorForm?.sumAssured?.questionHint,
                            },
                            label: {
                              value: calculatorForm?.sumAssured?.questionText,
                            },
                            "Css Class": {
                              value: "tool-tip",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: null,
                            "Is Tracking Enabled": {
                              value: true,
                            },
                            "Field Type": {
                              id: "fff6a7f6-db43-49f4-80aa-519af8ede3c5",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/basic/tooltip",
                              fields: {
                                BackgroundColor: {
                                  value: "Sky",
                                },
                                Icon: {
                                  value: "Office/32x32/question.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "1edd4140-c0f7-4b64-aaf6-dd99abd590de",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/tooltip",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomToolTipViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/ToolTip",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "131179784D46453C96D7EBC2817EB577",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                        {
                          id: "c8952a8d-5a09-49f9-ae50-97628fc459ae",
                          name: "sumAssuredButtonNextClick",
                          displayName: "sumAssuredButtonNextClick",
                          parentId: "ce204667-409d-401f-9585-c28ee8446b9e",
                          fields: {
                            "Navigation Step": {
                              value: "1",
                            },
                            emailTemplateLink: {
                              value: "",
                            },
                            "Css Class": {
                              value: "next-button",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4ff98d31e193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Label Css Class": {
                              value: "",
                            },
                            Title: {
                              value: calculatorForm?.nextButtonText,
                            },
                            "Field Type": {
                              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                              fields: {
                                BackgroundColor: {
                                  value: "Eggplant",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/button.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/button",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Button",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "B544A093CB784BB8AF1E0C9494DE48EE",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [
                            {
                              id: "6077e2dc-9da9-4b8a-b22c-1b88fa038efe",
                              name: "Redirect to Page",
                              displayName: "Redirect to Page",
                              parentId: "c8952a8d-5a09-49f9-ae50-97628fc459ae",
                              fields: {
                                Description: {
                                  value: "Phone Details",
                                },
                                Parameters: {
                                  value:
                                    '{"referenceId":"{ce204667-409d-401f-9585-c28ee8446b9f}"}',
                                },
                                "Submit Action": {
                                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                                  fieldType: "Droptree",
                                  fieldName: "Submit Action",
                                  url: "/sitecore/system/settings/forms/submit-actions/redirect-to-page",
                                  fields: {
                                    Editor: null,
                                    "Error Message": {
                                      value: "Redirect to page failed!",
                                    },
                                    "Model Type": {
                                      value:
                                        "Sitecore.ExperienceForms.Processing.Actions.RedirectToPage",
                                    },
                                  },
                                },
                              },
                              items: [],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      id: "ce204667-409d-401f-9585-c28ee8446b9f",
                      name: "paymentOptions",
                      displayName: "Payment Options",
                      parentId: "57ec00df-f75a-4645-a4f1-a830be98df45",
                      fields: {
                        gaLabel: {
                          value: "",
                        },
                        backgroundColor: {
                          id: "cbdc6265-d09f-4982-981a-bccff118f36f",
                          fieldType: "Droplink",
                          fieldName: "backgroundColor",
                          url: "/sitecore/content/fwd/global/settings/list/colors/whiteorange",
                          fields: {
                            value: {
                              value: "white-orange",
                            },
                            key: {
                              value: "WhiteOrange",
                            },
                          },
                        },
                        "Css Class": {
                          value: "sumAssured",
                        },
                        isHidden: {
                          value: false,
                        },
                        HelpText: {
                          value: "",
                        },

                        GeneralError: {
                          value: "",
                        },
                        SpecificError: {
                          value: "",
                        },
                        apiName: null,
                        "Field Type": {
                          id: "d819b43e-c136-4392-9393-8bf7fce32f5e",
                          fieldType: "Droptree",
                          fieldName: "Field Type",
                          url: "/sitecore/system/settings/forms/field-types/structure/page",
                          fields: {
                            BackgroundColor: {
                              value: "Eggplant",
                            },
                            Icon: {
                              value: "OfficeWhite/32x32/window_split_ver.png",
                            },
                            IsDisabled: {
                              value: false,
                            },
                            SpritePosition: {
                              value: "",
                            },
                            "Field Template": {
                              id: "cfee7b51-8505-45ce-b843-935ff827df87",
                              fieldType: "Droptree",
                              fieldName: "Field Template",
                              url: "/sitecore/templates/system/forms/fields/page",
                              fields: {},
                            },
                            "Allowed Validations": [],
                            "Model Type": {
                              value:
                                "FWD.Foundation.Forms.CustomPageViewModel,FWD.Foundation.Forms",
                            },
                            "Property Editor": null,
                            "View Path": {
                              value: "FieldTemplates/Page",
                            },
                          },
                        },
                        Conditions: {
                          value: '{"fieldConditions":[]}',
                        },
                        "Field Key": {
                          value: "9E94EEE6AE21451DBE91C3BBF376849A",
                        },
                        "Collapsed by Default": {
                          value: false,
                        },
                      },
                      items: [
                        {
                          id: "1789f54d-33c3-4045-baf6-bf2f85f712ae",
                          name: "Text",
                          displayName: "Text",
                          parentId: "ce204667-409d-401f-9585-c28ee8446b9e",
                          fields: {
                            "Html Tag": {
                              value: "p",
                            },
                            Text: {
                              value: calculatorForm?.paymentOptions?.prefix,
                            },
                            "Css Class": {
                              value: "inline",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4fff8d31e193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Field Type": {
                              id: "983bfa5f-c6b6-4aee-a3bb-46bfad52e7df",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/basic/text",
                              fields: {
                                BackgroundColor: {
                                  value: "Sky",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/document_text.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "fc18f915-eac6-460a-8777-6e1376a9ea09",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/text",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomTextViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Text",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "04BB8D346D4D4667B6D98F95CE78AB93",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                        {
                          id: "a8a831c4-ce45-4545-adb2-ef75a15688c8",
                          name: "paymentOptionsDropdowm",
                          displayName: "Payment Options",
                          parentId: "ce204667-409d-401f-9f85-c28eef446b9e",
                          fields: {
                            "Show Empty Item": {
                              value: true,
                            },
                            isAutoSuggest: {
                              value: false,
                            },
                            Datasource: {
                              id: "18de3e80-78b9-48b3-a1f1-aedf035f5306",
                              fieldType: "Droptree",
                              fieldName: "Datasource",
                              url: "/content/forms/contactdaylist-general-enquiry",
                              fields: {},
                            },
                            "Default Selection": {
                              value: "",
                            },
                            "Display Field Name": {
                              value: "value",
                            },
                            "Is Dynamic": {
                              value: true,
                            },
                            "Value Field Name": {
                              value: "key",
                            },
                            "Css Class": {
                              value: "inline",
                            },
                            isHidden: {
                              value: false,
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "58b136a7-764d-48c7-a721-a53f8fa01074",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/paymentFrequencyCd",
                              fields: {
                                value: {
                                  value: "paymentFrequencyCd",
                                },
                                key: {
                                  value: "paymentFrequencyCd",
                                },
                              },
                            },
                            "Label Css Class": {
                              value: "",
                            },
                            Title: {
                              value: `${calculatorForm?.paymentOptions?.options?.[0]?.item_key}`,
                            },
                            "Placeholder Text": {
                              value: "",
                            },
                            Required: {
                              value: true,
                            },
                            Validations: [],
                            "Is Tracking Enabled": {
                              value: true,
                            },
                            "Allow Save": {
                              value: true,
                            },
                            "Value Provider Parameters": {
                              value: "",
                            },
                            "Value Provider": null,
                            "Collapsed by Default": {
                              value: false,
                            },
                            "Field Type": {
                              id: "e0cfadee-1ac0-471d-a820-2e70d1547b4b",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "dropdown-list",
                              fields: {
                                BackgroundColor: {
                                  value: "Grass",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/drop_down_list.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "9121d435-48b8-4649-9d13-03d680474fad",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/dropdownlist",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomDropDownListViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/DropdownList",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "F388ECF875174833F4D66E2E2642B15B",
                            },
                          },
                          items:
                            calculatorForm?.paymentOptions?.options?.map(
                              (option: any) => ({
                                key: option?.item_key,
                                value: option?.item_value,
                              })
                            ) || [],
                          //itemType: "PAYMENT",
                        },
                        {
                          id: "33928375-a16b-4d42-9186-2acdf0737685",
                          name: "ToolTip",
                          displayName: "ToolTip",
                          parentId: "ce204667-409d-401f-9585-c28ee8446b9f",
                          fields: {
                            toolTipText: {
                              value:
                                calculatorForm?.paymentOptions?.questionHint ||
                                "",
                            },
                            label: {
                              value:
                                calculatorForm?.paymentOptions?.questionText ||
                                "",
                            },
                            "Css Class": {
                              value: "tool-tip",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: null,
                            "Is Tracking Enabled": {
                              value: true,
                            },
                            "Field Type": {
                              id: "fff6a7f6-db43-49f4-80aa-519af8ede3c5",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/basic/tooltip",
                              fields: {
                                BackgroundColor: {
                                  value: "Sky",
                                },
                                Icon: {
                                  value: "Office/32x32/question.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "1edd4140-c0f7-4b64-aaf6-dd99abd590de",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/tooltip",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomToolTipViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/ToolTip",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "08ACA529BB6048C09ADF023D64D0EDAD",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [],
                        },
                        {
                          id: "c8952a8d-5a09-49f9-ae50-97628fc459ae",
                          name: "seePlanSubmitClick",
                          displayName: "seePlanSubmitClick",
                          parentId: "ce204667-409d-401f-9585-c28eef446b9e",
                          fields: {
                            "Navigation Step": {
                              value: "1",
                            },
                            emailTemplateLink: {
                              value: "",
                            },
                            "Css Class": {
                              value: "submitButton",
                            },
                            isHidden: {
                              value: false,
                            },
                            HelpText: {
                              value: "",
                            },
                            GeneralError: {
                              value: "",
                            },
                            SpecificError: {
                              value: "",
                            },
                            apiName: {
                              id: "842f9760-ad09-47d5-a0bc-4ff98d31f193",
                              fieldType: "Droplink",
                              fieldName: "apiName",
                              url: "/sitecore/content/fwd/global/settings/list/form-api-fields/none",
                              fields: {
                                value: {
                                  value: "",
                                },
                                key: {
                                  value: "",
                                },
                              },
                            },
                            "Label Css Class": {
                              value: "",
                            },
                            Title: {
                              value: calculatorForm?.submitButtonText,
                            },
                            "Field Type": {
                              id: "7ce25cab-ef3a-4f73-ab13-d33bdc1e4ee2",
                              fieldType: "Droptree",
                              fieldName: "Field Type",
                              url: "/sitecore/system/settings/forms/field-types/structure/submit-button",
                              fields: {
                                BackgroundColor: {
                                  value: "Eggplant",
                                },
                                Icon: {
                                  value: "OfficeWhite/32x32/button.png",
                                },
                                IsDisabled: {
                                  value: false,
                                },
                                SpritePosition: {
                                  value: "",
                                },
                                "Field Template": {
                                  id: "94a46d66-b1b8-405d-aae4-7b5a9cd61c5e",
                                  fieldType: "Droptree",
                                  fieldName: "Field Template",
                                  url: "/sitecore/templates/system/forms/fields/button",
                                  fields: {},
                                },
                                "Allowed Validations": [],
                                "Model Type": {
                                  value:
                                    "FWD.Foundation.Forms.CustomButtonViewModel,FWD.Foundation.Forms",
                                },
                                "Property Editor": null,
                                "View Path": {
                                  value: "FieldTemplates/Button",
                                },
                              },
                            },
                            Conditions: {
                              value: '{"fieldConditions":[]}',
                            },
                            "Field Key": {
                              value: "B544A093CB784BB8FF1E0C9494DE48EE",
                            },
                            "Collapsed by Default": {
                              value: false,
                            },
                          },
                          items: [
                            {
                              id: "6077e2dc-9da9-4b8a-b22c-1b88fa038efe",
                              name: "Calculate Plans",
                              displayName: "Calculate Plans",
                              parentId: "c8952a8d-5a09-49f9-ae50-97628fc459ae",
                              fields: {
                                Description: {
                                  value: "See Plans",
                                },
                                Parameters: {
                                  value: '{"referenceId":"{}"}',
                                },
                                "Submit Action": {
                                  id: "3f3e2093-9dea-4199-86ca-44fc69ef624d",
                                  fieldType: "Droptree",
                                  fieldName: "Submit Action",
                                  url: "calculate",
                                  fields: {
                                    Editor: null,
                                    "Error Message": {
                                      value: "Calculate plans failed!",
                                    },
                                    "Model Type": {
                                      value:
                                        "Sitecore.ExperienceForms.Processing.Actions.SaveData",
                                    },
                                  },
                                },
                              },
                              items: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            "jss-footer": [],
          },
        },
      },
    },
  };
}
