import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import { CalculateProductType } from "context/EnquiryContext";
import { I18nContextData } from "i18n/context/LanguageContext";
import { findObjectByKeys } from "utils/object";
import { resolveAbsoluteUrl } from "utils/route";
import { generateUniqSerial } from "utils/uid";
import { LINK_TYPES, CTA_BUTTON_TYPES } from "utils/cta";
import { CONTENSTACK_FILE_URL, replaceContentStackURL } from "utils";
import { ProductCardListProps } from "./ProductCardListProps";
import gtmUtils, { gaEventAction } from "utils/gtm";

const getLinkTypeByButtonType = (buttonType: string) => {
  switch (buttonType) {
    case CTA_BUTTON_TYPES.enquire:
      return LINK_TYPES.form;

    case CTA_BUTTON_TYPES.disclosurePopup:
      return LINK_TYPES.modelpopup;

    case CTA_BUTTON_TYPES.buyNow:
    default:
      return LINK_TYPES.external;
  }
};

export function getPlanCards(
  props: ProductCardListProps,
  i18nContext: I18nContextData,
  updateStatus: any
) {
  return props.product_plan?.map((plan) => {
    let coverage: { [key: string]: string } = {};
    let gtmCoverage: string[] = [];

    if (plan?.summary?.length) {
      plan?.summary?.forEach((e) => {
        if (e.category.length) {
          e.category.forEach((category) => {
            coverage = { ...coverage, [category.key]: category.value };
            if (category.push_to_gtm) {
              gtmCoverage.push(category.value);
            }
          });
        }
      });
    }

    const ctaLinkTarget =
      plan?.cta_buttons?.open_options === "in_new_tab" ? "_blank" : "_self";

    return {
      id: plan?.uid,
      updateGroupEnquiryData: () => {

      },
      gtmNagivationCallback: (data: any) => {
        if (data) {
          gtmUtils.navigation({
            event_action: gaEventAction.button_click,
            button_title: data.buttonTitle,
            destination_url: data.destinationUrl,
            component_name: data.componentName,
            product_affiliation:
              i18nContext.componentConfigs?.gaPageData?.product_affiliation ||
              "",
          });
        }
      },
      fields: {
        productPlanOptionCd: {
          value: plan?.product_plan_option_cd,
        },
        planCode: {
          value: plan?.plan_code,
        },
        isPackage: plan?.plan_type?.[0]?.key === "package",
        baseSumassured: plan?.base_sumassured,
        packageList: plan?.summary?.map((s) => ({
          fields: {
            title: {
              value: s.title,
            },
            cardAttributes: {
              value: s.category?.reduce(
                (pv, cv) => ({ ...pv, [cv.key]: cv.value || "-" }),
                {}
              ),
            },
          },
        })),
        cardTitle: {
          value: "",
        },
        gtmCoverage,
        cardAttributes: {
          value: coverage,
        },
        key: {
          value: "",
        },
        value: {
          value: plan?.tooltip,
        },
        eCommerceSecondary: {
          value: false,
        },
        share: {
          value: true,
        },
        cardLabel: {
          value: plan?.plan_type?.[0]?.title,
        },
        title: {
          value: plan?.display_title || plan?.title || "Plans",
        },
        description: {
          value: "",
        },
        isFeatured: {
          value: false,
        },
        buttonIcon: null,
        linkGALabelName: {
          value: plan?.cta_buttons?.title || "",
        },
        buttonMode: {
          id: "2d7e3979-8dae-42d1-bfdf-45522db9a1b9",
          fieldType: "Droplink",
          fieldName: "buttonMode",
          url: "/sitecore/content/fwd/global/settings/list/button-mode/primary",
          fields: {
            value: {
              value: "Primary",
            },
            key: {
              value: "Primary",
            },
          },
        },
        eCommercePrimary: {
          value: false,
        },
        link: plan?.cta_buttons?.title
          ? {
            value: {
              linktype: getLinkTypeByButtonType(plan?.cta_buttons?.type),
              href: resolveAbsoluteUrl(plan?.cta_buttons?.url, i18nContext),
              text: plan?.cta_buttons?.title,
              url: resolveAbsoluteUrl(plan?.cta_buttons?.url, i18nContext),
              anchor: "",
              target: ctaLinkTarget,
              disclosurepopupcontent: !isEmpty(
                plan?.cta_buttons?.disclosure_popup
              )
                ? {
                  disclosure: {
                    value: get(
                      plan?.cta_buttons?.disclosure_popup,
                      "[0].uid",
                      ""
                    ),
                  },
                }
                : null,
            },
            setDisclosurePopupList: () => { },
          }
          : null,
        hasDisclosurePopup: !isEmpty(plan?.cta_buttons?.disclosure_popup),
        disclosurePopup: !isEmpty(plan?.cta_buttons?.disclosure_popup)
          ? plan?.cta_buttons?.disclosure_popup[0]
          : null,
        minAge: {
          value: "yy-mm-dd",
        },
        maxAge: {
          value: "yy-mm-dd",
        },
        enableEnquiry: {
          value: false,
        },
        channel: {
          value: "",
        },
        minimumSumAssuredRule: [],
        maximumSumAssured: {
          value: "",
        },
        minimumSumAssured: {
          value: "",
        },
        minimumPremiumYearly: {
          value: "",
        },
        maximumPremiumYearly: {
          value: "",
        },
        premiumIndicativePrice: {
          value: "",
        },
        frequency: null,
        sumAssuredIndicativePrice: {
          value: "",
        },
        isComparable: {
          value: props?.comparison?.enable,
        },
        gaLabelName: {
          value: plan?.title || "",
        },
        gaCategoryName: {
          value: "",
        },
        secondaryLinkGALabelName: {
          value: "",
        },
        secondaryLink: {
          value: plan?.secondary_link?.title
            ? {
              linktype: "external",
              target: plan?.secondary_link?.open_in_new_tab
                ? "_blank"
                : "_self",
              text: plan?.secondary_link?.title,
              href: plan?.secondary_link?.url?.includes(CONTENSTACK_FILE_URL)
                ? replaceContentStackURL(plan?.secondary_link?.url)
                : plan?.secondary_link?.url
                  ? plan?.secondary_link?.url
                  : plan?.secondary_link?.file?.url
                    ? replaceContentStackURL(plan?.secondary_link?.file?.url)
                    : "",
            }
            : {},
        },
        secondaryLinkButtonIcon: plan?.secondary_link
          ? {
            id: "7ea908fb-506f-45e9-ab04-2b33b5dcde2a",
            fieldType: "CustomDroplink",
            fieldName: "secondaryLinkButtonIcon",
            url: "/sitecore/content/fwd/global/settings/list/linkvariant/link",
            fields: {
              value: {
                value: plan?.secondary_link?.icon,
              },
              key: {
                value: "Link",
              },
            },
          }
          : null,
        uniqueId: plan?.uid,
      },
      dictionary: {
        ...plan?.dictionary?.table?.value?.reduce(
          (obj: any, item: any) =>
            Object.assign(obj, { [item.key]: item.value }),
          {}
        ),
        coverageSummary: plan?.summary_label,
      },
      setSelectedPlanCard: (planName: any) => {
        const data = {
          productName: planName.productName,
          isGeneralEnquiryForm: false,
          isFromCalculation: true,
          productPlanId: plan?.uid,
        };
        updateStatus(data);
      },
      updateFormInitializer: () => { },
      updateFormModalState: () => { },
      updateDisclosurePopup: function ({ setDisclosurePopupList, disclosurepopupcontent }: any) {
        const self = this;
        set(self.fields, 'link.setDisclosurePopupList', setDisclosurePopupList);
      },
      appInSession: () => { },
    };
  });
}

export const getCalculate = (
  csData: any,
  productGroupName: string,
  planCards: any,
  calculateProduct: (data: CalculateProductType) => void
) => {
  const form = csData?.calculate?.enquiry_type?.[0];

  const plans: any = {};

  planCards?.forEach((plan: any) => {
    if (plan?.fields?.planCode?.value) {
      plans[plan?.fields?.planCode?.value] = [
        {
          name: plan?.fields?.planCode?.value,
          productOption: [plan?.fields?.productPlanOptionCd?.value],
          isPackage: plan?.fields?.isPackage,
          baseSumassured: plan?.fields?.baseSumassured,
        },
      ];
    }
  });

  const constraints = {
    limitAmount: findObjectByKeys(form?.modular_blocks, "sum_assured")
      ?.limit_amount,
    limitAge: findObjectByKeys(form?.modular_blocks, "sum_assured")?.limit_age,
  };

  return {
    setSelectedCalculate: () => {
      calculateProduct({
        name: productGroupName,
        productCode: csData?.product_code,
        form,
        plans,
        planCards,
        confirmationForm: {},
        riders: [],
        type: "calculate",
        planRecommender: {},
        constraints,
      });
    },
  };
};

export const handleEditCalculateForm = (
  handleFormModalState: (state: boolean) => void
) => {
  return {
    onEditClickHandler: () => {
      handleFormModalState(true);
    },
  };
};

export const handleResetCalculateForm = (
  handleFormModalState: (state: boolean) => void
) => {
  return {
    onResetClickHandler: () => {
      handleFormModalState(false);
    },
  };
};

export const handleEnquiryForm = (updateStatus: (data: any) => void) => {
  return {
    onEnquiryHandler: (
      productName: string,
      isGeneralEnquiryForm: boolean,
      riderList: any
    ) => {
      updateStatus({
        productName,
        isGeneralEnquiryForm,
        type: "recommender_plan",
        riderList,
        isFromCalculation: true,
      });
    },
  };
};
