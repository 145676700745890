
import get from "lodash/get";

function findObjectByKeys(arr: any = [], keyName: string = "") {
  for (var i = 0; i < arr.length; i++) {
    const obj = get(arr[i], keyName);
    if (obj) {
      return obj;
    }
  }
  return null;
}

export {
  findObjectByKeys,
};